import { HitResultType } from "../usecases/SearchHousesAndDestinations/interfaces/SearchHousesAndDestinationsPresentation";
import { HousePeriodsResponseDataFormatted } from "../usecases/GetHousePeriods/types";
import {
  BookingsPeriods,
  PeriodDurationInquiry,
  SearchHouse,
  FacetsRetrieve,
  HouseDetailsAttributes,
  HousePhotosAttributesFormatted,
  HousesCoordinates,
  HouseIncludedService,
  HouseTruthLink,
  SimilarHousePrices,
  SearchDestinations,
} from "../../../types";
import { MakeHouseRequestResponseData } from "../usecases/MakeHouseRequest/types";
import { UpdateInquiryResponseData } from "../usecases/UpdateInquiry/types";
import {
  HouseAreaEquipment,
  HouseKeyFeatures,
} from "../usecases/GetHouseDetails/types";
import { ApiError } from "lc-repository/dist/repositories/types";
import { HousePeriodsIdResponseData } from "lc-repository/dist/repositories/lc/v3/houses/periods";

export class SearchVM {
  msg = "";
  algoliaQueryId: string | undefined = undefined;
  housesResult: HitResultType = [];
  destinationsResult: HitResultType = [];
  destinations?: SearchDestinations[] = undefined;
  topDestinationsResult: HitResultType = [];
  searchHousesResult: SearchHouse[] = [];
  nbHouses = 0;
  coordinatesBoundaries?: {
    min_coords: [number, number];
    max_coords: [number, number];
  } = undefined;
  destinationFavoritePeriod = { percent: 0, period: "" };
  houseCoordinates: HousesCoordinates | undefined = undefined;
  nbPages = 0;
  searchCurrentPage = 0;
  facets: FacetsRetrieve = {};
  orderedFacets: FacetsRetrieve = {};
  houseAreaEquipments: HouseAreaEquipment[] | undefined = undefined;
  houseDetails: (HouseDetailsAttributes & { houseUrl: string }) | null = null;
  houseIncludedServices: HouseIncludedService[] | [] = [];
  houseTruthLinks: Pick<HouseTruthLink, "description">[] = [];
  houseKeyFeatures: HouseKeyFeatures | null = null;
  housePhotos: HousePhotosAttributesFormatted | null = null;
  housePeriodsById: HousePeriodsIdResponseData | null = null;
  housePeriods: HousePeriodsResponseDataFormatted | null = null;
  houseRequest: MakeHouseRequestResponseData | null = null;
  similarHousePrices: SimilarHousePrices[] | null = null;
  houseRequestError = "";
  isLoading = false;
  isHousePhotosLoading = false;
  isSequenceLoading = false;
  requestedHouseInfo: {
    [houseId: string]: {
      dateRequested: Date;
      price: string;
      startDate: string;
      endDate: string;
    };
  } | null = null;
  periodsDurations?: PeriodDurationInquiry[] = [];
  bookings?: BookingsPeriods = [];
  updatedInquiry: UpdateInquiryResponseData | null = null;
  inquiryError = "";
  nbHiddenHouses = 0;
  error: ApiError | null = null;
  destinationPhoto: { id: string; photo?: string } | null = null;
}
