import { GetSearchParamsType } from "../usecases/SearchHouses/types";
import {
  formatNumericFilters,
  formatReplicas,
  formatFacetFilters,
  generateDefaultFacets,
  PRICES,
} from "./helpers";
import { SearchQueries } from "./SearchQueries";
import {
  FacetFilter,
  FacetsRetrieve,
  HousePeriodsIdsResponseData,
  HousesCoordinates,
  Locale,
  Mode,
  SearchHouse,
  SupportedCurrencies,
} from "../../../types";

export class Search extends SearchQueries {
  private hitsPerPage = 15;
  private memoryDefaultFacets: string[] = [];
  protected facetsFilters: FacetsRetrieve | undefined = {};
  protected chunkedHits: Array<SearchHouse[]> = [];
  protected currentHits: SearchHouse[] = [];
  protected currentPage = 0;
  protected coordinatesBoundaries?: {
    min_coords: [number, number];
    max_coords: [number, number];
  } = undefined;
  protected housesCoordinates?: HousesCoordinates = undefined;
  protected nbHits = 0;
  protected nbPages = 0;
  protected validHousesIds: number[] = [];
  protected invalidHousesIds: number[] = [];
  protected sortedHits: SearchHouse[] = [];
  protected currency: SupportedCurrencies = "EUR";
  protected locale: Locale = "fr";
  protected maxBudget = "1000000";
  protected minBudget = "0";
  protected period?: {
    startDate: string;
    endDate: string;
  } = undefined;

  protected initializeList() {
    this.chunkedHits = [];
    this.currentHits = [];
    this.currentPage = 0;
    this.nbHits = 0;
    this.nbPages = 0;
  }

  protected initializeMap() {
    this.housesCoordinates = undefined;
    this.coordinatesBoundaries = undefined;
  }

  protected setLocale(locale: Locale) {
    this.locale = locale;
  }

  protected setCurrency(currency?: SupportedCurrencies) {
    this.currency = currency ? this.formatCurrency(currency) : this.currency;
  }

  protected setMaxBudget(prices?: number[]) {
    this.maxBudget = prices && prices[1] ? String(prices[1]) : this.maxBudget;
  }

  protected setMinBudget(prices?: number[]) {
    this.minBudget = prices && prices[0] ? String(prices[0]) : this.minBudget;
  }

  protected setPeriod(period?: { startDate: string; endDate: string }) {
    this.period = period;
  }

  protected getDistinctHits(hits: SearchHouse[]) {
    return hits.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
  }

  protected getHits(housesIds: string[]) {
    return housesIds.length > 0
      ? this.sortedHits
      : this.chunkedHits[this.currentPage] || [];
  }

  protected setSortedHits(sortedHits: SearchHouse[]) {
    this.sortedHits = sortedHits;
  }

  protected setHitsPerPage(per: number) {
    this.hitsPerPage = per;
  }

  protected canPaginate() {
    return this.currentPage <= this.nbPages;
  }

  protected setNbPages(nbPages: number) {
    this.nbPages = nbPages;
  }

  protected setNbHits(nbHits: number) {
    this.nbHits = nbHits;
  }

  protected setHousesCoordinates(housesCoordinates: HousesCoordinates) {
    this.housesCoordinates = housesCoordinates;
  }

  protected setCoordinatesBoundaries(boundaries: {
    min_coords: [number, number];
    max_coords: [number, number];
  }) {
    this.coordinatesBoundaries = boundaries;
  }

  protected setCurrentPage(page: number) {
    this.currentPage = page;
  }

  protected formatCurrency(
    currency: SupportedCurrencies | null
  ): SupportedCurrencies {
    return currency ? JSON.parse(JSON.stringify(currency)) : "EUR";
  }

  protected sortedHitsWithPeriodPrices(hits: SearchHouse[]) {
    return [
      ...hits
        .filter((hit) => !hit.isInvalid && hit.display_prices)
        .sort((a, b) => (a.publicPrice as number) - (b.publicPrice as number)),
      ...hits.filter((hit) => !hit.isInvalid && !hit.display_prices),
      ...hits
        .filter((hit) => hit.isInvalid && hit.display_prices)
        .sort((a, b) => (a.publicPrice as number) - (b.publicPrice as number)),
      ...hits.filter((hit) => hit.isInvalid && !hit.display_prices),
    ];
  }

  protected chunkHits(hits: SearchHouse[]) {
    return hits.reduce(
      (chunkedArray, item, index) => {
        const chunkIndex = Math.floor(index / this.hitsPerPage);

        if (!chunkedArray[chunkIndex]) {
          chunkedArray[chunkIndex] = [];
        }

        chunkedArray[chunkIndex].push(item);

        return chunkedArray;
      },
      [] as Array<SearchHouse[]>
    );
  }

  protected formatInvalidHits(invalidHits: SearchHouse[]) {
    return invalidHits.map((hit) => ({ ...hit, isInvalid: true }));
  }

  private sortHitsByApi(validHits: SearchHouse[], houseIds: number[]) {
    return [...validHits].sort((a, b) => {
      const A = a.id;
      const B = b.id;

      if (houseIds.indexOf(A) > houseIds.indexOf(B)) return 1;
      return -1;
    });
  }

  protected sortHitsByPublicPrice(hits: SearchHouse[]) {
    return [...hits].sort(
      (a, b) => Number(b.display_prices) - Number(a.display_prices)
    );
  }

  protected sortHits(
    hits: SearchHouse[],
    houseIds: number[],
    type: "valid" | "invalid"
  ) {
    if (houseIds.length) {
      const filteredHits = [...hits].filter((h) => houseIds.includes(h.id));
      const sortedHitsByApi = this.sortHitsByApi(filteredHits, houseIds);
      const sortedHitsByPrice = this.sortHitsByPublicPrice(sortedHitsByApi);

      return [...sortedHitsByPrice];
    }

    return type === "valid" ? [...this.sortHitsByPublicPrice(hits)] : [];
  }

  protected formatCoordinates(
    hits: SearchHouse[],
    locale: Locale,
    isValid?: boolean
  ) {
    if (hits?.length) {
      const validCoordinates = hits
        .filter(
          (hit) =>
            Number(parseFloat(hit.gpslatitude)) && parseFloat(hit.gpslongitude)
        )
        .filter((hit) => hit.isInvalid === undefined);
      this.searchBoundaries(validCoordinates);
      const coordinatesFormatted = {
        type: "FeatureCollection",
        features: validCoordinates.map((house) => ({
          type: "Feature",
          properties: {
            bathrooms: house.bathrooms,
            bedrooms: house.bedrooms,
            capacity: house.capacity,
            destination: house.destination[locale],
            displayPrice: isValid ? house.display_prices : false,
            houseId: house.id,
            houseName: house.name,
            isSelected: false,
            maxPrice: house.max_week_price,
            minPrice: house.min_week_price,
            photo: house.first_photo_url,
            photos: house.photos,
            price: house?.publicPrice,
            slug: house.slug[locale],
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(house.gpslongitude),
              parseFloat(house.gpslatitude),
            ],
          },
          id: parseFloat(house.gpslongitude) + parseFloat(house.gpslongitude),
        })),
      };
      this.setHousesCoordinates(coordinatesFormatted);
    }
  }

  private searchBoundaries = (validHouse: SearchHouse[]) => {
    const lat = validHouse.map((house) => parseFloat(house.gpslatitude));
    const lng = validHouse.map((house) => parseFloat(house.gpslongitude));

    const min_coords: [number, number] = [
      Math.min.apply(null, lng),
      Math.min.apply(null, lat),
    ];
    const max_coords: [number, number] = [
      Math.max.apply(null, lng),
      Math.max.apply(null, lat),
    ];
    this.setCoordinatesBoundaries({
      min_coords,
      max_coords,
    });
  };

  private setValidSearchHousesIds(validHousesIds?: number[]) {
    this.validHousesIds = validHousesIds ? validHousesIds : [];
  }

  private setInvalidSearchHousesIds(invalidHousesIds?: number[]) {
    this.invalidHousesIds = invalidHousesIds ? invalidHousesIds : [];
  }

  private periodHousesIdsFormatter(validIds: number[], invalidIds: number[]) {
    const ids = validIds.concat(invalidIds);
    return ids.length > 0
      ? `AND (${ids.map((num) => `id=${num.toString()}`).join(" OR ")})`
      : "";
  }

  private housesIdsFormatter(ids: string[]) {
    return ids.length > 0
      ? `AND (${ids.map((num) => `id=${num.toString()}`).join(" OR ")})`
      : "";
  }

  private pricesAlgoliaFormatter(
    deviceMode: "web" | "mobile",
    minPrice: number,
    maxPrice: number,
    currency: SupportedCurrencies,
    housesPeriodIds: HousePeriodsIdsResponseData | undefined
  ) {
    const {
      MULTIPLIER,
      MIN_PRICE_CHOICE,
      MAX_PRICE_CHOICE,
      MAX_PRICE_REQUEST,
    } = PRICES;
    if (deviceMode === "mobile") {
      if (
        (minPrice === MIN_PRICE_CHOICE && maxPrice === MAX_PRICE_CHOICE) ||
        housesPeriodIds
      ) {
        return "";
      } else if (
        minPrice !== MIN_PRICE_CHOICE &&
        maxPrice >= MAX_PRICE_CHOICE
      ) {
        return `AND max_week_price.${currency} >= ${Math.round(
          minPrice * MULTIPLIER
        )} AND max_week_price.${currency} <= ${MAX_PRICE_REQUEST}`;
      }
      // web
    }
    if (housesPeriodIds) return "";

    return `AND max_week_price.${currency} >= ${Math.round(
      minPrice * MULTIPLIER
    )} AND max_week_price.${currency} <= ${Math.round(maxPrice * MULTIPLIER)}`;
  }

  protected getSearchParams(
    input: GetSearchParamsType,
    deviceMode: "web" | "mobile",
    mode: Mode,
    locale?: Locale
  ) {
    const newQuery = input.destinationIds.length > 0 ? "" : input.query;
    const insideBoundingBox =
      input.insideBoundingBox && input.insideBoundingBox.length
        ? input.insideBoundingBox
        : undefined;
    const prices = this.pricesAlgoliaFormatter(
      deviceMode,
      input.minPrice,
      input.maxPrice,
      input.currency,
      input.housesPeriodIds
    );
    const destinationIds =
      input.destinationIds.map((id) => `destination_ids:${id}`) || [];
    const numericFilters = formatNumericFilters({
      bathrooms: input.bathrooms,
      bedrooms: input.bedrooms,
      capacity: input.capacity,
    });
    const replica = formatReplicas(input.replicaValue, mode);
    const facetFilters: FacetFilter[] = [
      destinationIds,
      ...formatFacetFilters(input.facetFilters),
    ].filter((f) => f.length);
    if (locale)
      this.memoryDefaultFacets = this.memoryDefaultFacets.length
        ? this.memoryDefaultFacets
        : generateDefaultFacets(locale, mode);

    this.setValidSearchHousesIds(input.housesPeriodIds?.validHousePeriodsIds);
    this.setInvalidSearchHousesIds(
      input.housesPeriodIds?.invalidHousePeriodsIds
    );

    const periodHousesIdsFormatted = this.periodHousesIdsFormatter(
      this.validHousesIds,
      this.invalidHousesIds
    );

    return {
      defaultFacets: this.memoryDefaultFacets,
      facetFilters,
      insideBoundingBox,
      housesIds: input.period
        ? periodHousesIdsFormatted
        : this.housesIdsFormatter(input.housesIds),
      newQuery,
      numericFilters,
      prices,
      replica,
    };
  }
}
