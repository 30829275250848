import { SearchHousesMapPresentation } from "./interfaces/SearchHousesMapPresentation";
import {
  SearchHousesWithDatesRepository,
  SearchRepository,
} from "../SearchHouses/SearchHousesWithDates/interfaces/SearchHousesWithDatesRepository";
import SearchHousesUseCaseWithDates from "../SearchHouses/SearchHousesWithDates/SearchHousesWithDatesUseCase";
import { GetSearchParamsType } from "../SearchHouses/types";
import { Search } from "../../domain";
import { Locale, Mode, SearchHousesMapInput } from "../../../../types";
import SearchHousesWithoutDatesUseCase from "../SearchHouses/SearchHousesWithoutDates/SearchHousesWithoutDatesUseCase";
import { SearchType } from "../../../../services/algolia";

export default class SearchHousesMapUseCase extends Search {
  constructor(private repository: SearchHousesWithDatesRepository) {
    super();
  }
  private async withPeriodCase(
    input: GetSearchParamsType,
    presenter: SearchHousesMapPresentation,
    mode: Mode,
    locale: "fr" | "en",
    repository: SearchRepository,
    deviceMode: "web" | "mobile"
  ) {
    const searchHouses = new SearchHousesUseCaseWithDates(this.repository);
    const housePeriodIdsResponse = await this.getHouseIdsPeriodResponse({
      currency: input.currency,
      destinationIds: input.destinationIds || [],
      deviceMode,
      housesIds: input.housesIds || [],
      maxBudget: input.maxPrice,
      minBudget: input.minPrice,
      period: this.period,
      repository,
      sortBy: input.replicaValue,
    });
    const housesPeriodIds = housePeriodIdsResponse?.data;
    if (housesPeriodIds) {
      const hits = await searchHouses.getSearchHousesInfo(
        { ...input, housesPeriodIds },
        deviceMode,
        mode,
        repository
      );
      const validHouses = housesPeriodIds.validHousePeriodsIds;
      const invalidHouses = housesPeriodIds.invalidHousePeriodsIds;
      const sortedValidHits = this.sortHits(hits, validHouses, "valid");
      const sortedInvalidHits = this.sortHits(hits, invalidHouses, "invalid");
      const hitsWithPeriodPrices = await this.getHousePeriodsResponse({
        deviceMode,
        currency: input.currency,
        hits: sortedValidHits.length ? sortedValidHits : sortedInvalidHits,
        maxBudget: input.maxPrice,
        minBudget: input.minPrice,
        period: this.period,
        repository,
        sortBy: input.replicaValue,
      });
      this.facetsFilters = await this.getSearchFacetsWithPeriod({
        input: { ...input, housesPeriodIds },
        mode,
        getSearchParams: () =>
          this.getSearchParams(input, deviceMode, mode, locale as Locale),
        locale,
        repository,
      });
      this.setNbHits(hits.length);
      this.formatCoordinates(
        hitsWithPeriodPrices,
        this.locale,
        Boolean(sortedValidHits.length)
      );
      presenter.displayCoordinatesBoundaries(this.coordinatesBoundaries);
      presenter.displayHousesCoordinates(this.housesCoordinates);
      presenter.displayFacets(this.facetsFilters);
      presenter.displayNbHouses(this.nbHits);
    }
  }

  private async withoutPeriodCase(
    input: GetSearchParamsType,
    presenter: SearchHousesMapPresentation,
    mode: Mode,
    locale: "fr" | "en",
    repository: SearchRepository,
    deviceMode: "web" | "mobile"
  ) {
    const searchHouses = new SearchHousesWithoutDatesUseCase(this.repository);
    const hits = await searchHouses.getSearchHousesInfo(
      input,
      deviceMode,
      mode,
      repository
    );
    const sortedHits = this.sortHitsByPublicPrice(hits);
    this.formatCoordinates(sortedHits, this.locale, Boolean(sortedHits.length));
    this.facetsFilters = await this.getSearchFacetsWithPeriod({
      input: { ...input },
      mode,
      getSearchParams: () =>
        this.getSearchParams(input, deviceMode, mode, locale as Locale),
      locale,
      repository,
    });
    this.setNbHits(hits.length);
    presenter.displayCoordinatesBoundaries(this.coordinatesBoundaries);
    presenter.displayHousesCoordinates(this.housesCoordinates);
    presenter.displayFacets(this.facetsFilters);
    presenter.displayNbHouses(this.nbHits);
  }

  async execute(
    presenter: SearchHousesMapPresentation,
    input: SearchHousesMapInput,
    algoliaIds: SearchType,
    deviceMode: "web" | "mobile"
  ) {
    const { getHousePeriodsIds, getHousePeriods, algolia } = this.repository;
    const repository = {
      getHousePeriodsIds,
      getHousePeriods,
      algolia: algolia(algoliaIds),
    };
    this.initializeMap();
    this.setCurrency(input.currency);
    this.setMaxBudget(input.prices);
    this.setMinBudget(input.prices);
    this.setPeriod(input.period);
    this.setLocale(input.locale);
    const maxBudget = Number(this.maxBudget);
    const minBudget = Number(this.minBudget);
    const mode = input.mode || "client";
    const locale = input.locale || "fr";

    const inputSearchParams: GetSearchParamsType = {
      bathrooms: input.bathrooms || "",
      bedrooms: input.bedrooms || "",
      capacity: input.capacity || "",
      currency: this.currency,
      destinationIds: input.destinationIds || [],
      facetFilters: input.facetFilters || new Map(),
      housesIds: input.housesIds || [],
      maxPrice: maxBudget,
      minPrice: minBudget,
      page: input.page || 0,
      query: "",
      replicaValue: "recommended",
      insideBoundingBox: input.insideBoundingBox,
    };

    if (this.period) {
      await this.withPeriodCase(
        inputSearchParams,
        presenter,
        mode,
        locale,
        repository,
        deviceMode
      );
    } else {
      await this.withoutPeriodCase(
        inputSearchParams,
        presenter,
        mode,
        locale,
        repository,
        deviceMode
      );
    }
  }
}
