export type MenuKey = 'summary' | 'inspirations' | 'destinations' | 'aboutUs'

export const useHeaderV2 = () => {
  const PAGE_WITHOUT_HEADER_STICKY = [
    'desktop:index',
    'mobile:index',
    'mobile:search',
  ]
  const PAGE_HEADER_WITHOUT_ANIMATION = ['search']
  const PAGE_HEADER_SEARCH_HIDDEN = [
    'desktop:house-slug',
    'desktop:index',
    'mobile:house-slug',
    'mobile:index',
    'mobile:search',
  ]

  const { isMobile, isSmallScreen } = useBreakpoint()
  const { userIsAdmin, userIsPartner } = useAuth()
  const route = useRoute()

  const headerV2 = useState<{
    contactOpen: boolean
    languageCurrencyOpen: boolean
    menukey: MenuKey
    menuOpen: boolean
    searchMobileOpen: boolean
    signInChoiceOpen: boolean
  }>('header-v2', () => ({
    contactOpen: false,
    languageCurrencyOpen: false,
    menukey: 'summary',
    menuOpen: false,
    searchMobileOpen: false,
    signInChoiceOpen: false,
  }))

  const isNewHeader = computed(() => !userIsAdmin.value && !userIsPartner.value)

  const isLp = computed(() => route.meta?.destinationPage?.isLp || false)
  const height = computed(() => (isMobile.value ? 72 : 85))
  const contactOpen = computed(() => headerV2.value.contactOpen)
  const menuOpen = computed(() => headerV2.value.menuOpen)
  const menuActiveKey = computed(() => headerV2.value.menukey)
  const languageCurrencyOpen = computed(
    () => headerV2.value.languageCurrencyOpen,
  )
  const signInChoiceOpen = computed(() => headerV2.value.signInChoiceOpen)
  const searchMobileOpen = computed(() => headerV2.value.searchMobileOpen)

  const nameRoute = computed(() => String(route.name ?? '').slice(0, -5))

  const headerHandler = computed(() => {
    const isSticky =
      (!PAGE_WITHOUT_HEADER_STICKY.includes(`desktop:${nameRoute.value}`) &&
        !isSmallScreen.value) ||
      (isSmallScreen.value &&
        !PAGE_WITHOUT_HEADER_STICKY.includes(`mobile:${nameRoute.value}`))

    const isStickyWithAnimation =
      nameRoute.value === 'destination-name'
        ? isLp.value
        : !PAGE_HEADER_WITHOUT_ANIMATION.includes(nameRoute.value)

    return {
      isStickWithoutAnimation: isSticky && !isStickyWithAnimation,
      isStickyWithAnimation: isSticky && isStickyWithAnimation,
    }
  })

  const headerSearchHandler = computed(() => {
    const mobileCondition =
      nameRoute.value === 'destination-name'
        ? isLp.value
        : !PAGE_HEADER_SEARCH_HIDDEN.includes(`mobile:${nameRoute.value}`)

    const showSearch =
      (!PAGE_HEADER_SEARCH_HIDDEN.includes(`desktop:${nameRoute.value}`) &&
        !isSmallScreen.value) ||
      (mobileCondition && isSmallScreen.value)

    return { showSearch }
  })

  const setMenuOpen = (value: boolean) => {
    headerV2.value.menuOpen = value
    headerV2.value.menukey = 'summary'
  }

  const setContactOpen = (value: boolean) => {
    headerV2.value.contactOpen = value
  }

  const setLanguageCurrencyOpen = (value: boolean) => {
    headerV2.value.languageCurrencyOpen = value
  }

  const setSignInChoiceOpen = (value: boolean) => {
    headerV2.value.signInChoiceOpen = value
  }

  const setSearchMobileOpen = (value: boolean) => {
    headerV2.value.searchMobileOpen = value
  }

  const setMenuNavigation = (key: MenuKey) => {
    headerV2.value.menukey = key
  }

  return {
    contactOpen,
    headerHandler,
    headerSearchHandler,
    height,
    isNewHeader,
    languageCurrencyOpen,
    menuActiveKey,
    menuOpen,
    searchMobileOpen,
    setContactOpen,
    setLanguageCurrencyOpen,
    setMenuNavigation,
    setMenuOpen,
    setSearchMobileOpen,
    setSignInChoiceOpen,
    signInChoiceOpen,
  }
}
