import dayjs from 'dayjs'
import { defineRule, configure } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'
import {
  confirmed,
  email,
  length,
  max_value,
  max,
  min_value,
  min,
  numeric,
  regex,
  required,
} from '@vee-validate/rules'
import { validatePeriodAgainstBookedDates } from '~/utils/date'

import fr from '@vee-validate/i18n/dist/locale/fr.json'
import en from '@vee-validate/i18n/dist/locale/en.json'

export default defineNuxtPlugin((nuxtApp) => {
  const { $i18n } = nuxtApp

  const min8Characters = /.{8}/
  const lowercase = /[a-z]/
  const uppercase = /[A-Z]/
  const specialCharacterOrNumber = /[^a-zA-Z_]/

  const gdprRulesDefault = [
    { state: null, name: 'min8', regex: min8Characters },
    { state: null, name: 'lowercase', regex: lowercase },
    { state: null, name: 'uppercase', regex: uppercase },
    {
      state: null,
      name: 'specialCharacterOrNumber',
      regex: specialCharacterOrNumber,
    },
  ]

  defineRule('confirmed', confirmed)
  defineRule('email', email)
  defineRule('length', length)
  defineRule('max_value', max_value)
  defineRule('max', max)
  defineRule('min_value', min_value)
  defineRule('min', min)
  defineRule('numeric', numeric)
  defineRule('regex', regex)
  defineRule('required', required)

  // Custom rules
  defineRule('phone_invalid', () => {
    return false
  })
  defineRule('UTF8Characters', (value) => {
    let valid = true

    for (let i = 0; i < value.length; i++) {
      const character = value[i]
      // the regex below lists all the authorized characters (note: it returns true if it's NOT an authorized character)
      const notUTF8 =
        /[^a-zA-Z0-9.'()\-^ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝàáâãäåæçèéêëìíîïñòóôõöùúûüýÿß\s]/.test(
          character,
        )

      if (notUTF8) {
        valid = false
      }
    }

    return valid
  })
  defineRule('dateFormat', (value) => {
    const dateRegex = /\d{2}\/\d{2}\/\d{4}$/

    return dateRegex.test(value)
  })
  defineRule('validBirthdate', (value) => {
    const dateRegex = /(\d{2})\/(\d{2})\/(\d{4})$/

    if (!dateRegex.test(value)) {
      return false
    }

    const [, day, month] = value.match(dateRegex)

    const monthNumber = parseInt(month, 10)
    const dayNumber = parseInt(day, 10)
    if (monthNumber < 1 || monthNumber > 12) return false
    if (dayNumber < 1 || dayNumber > 31) return false

    return true
  })
  defineRule('validDateOfBirth', (value, [locale] = ['fr']) => {
    const birthdayDate = dayjs.utc(
      value,
      locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
    )
    return dayjs().diff(birthdayDate, 'years') >= 18
  })
  defineRule('calendarRequired', (value) => {
    return Boolean(value.startDate) && Boolean(value.endDate)
  })
  // the second argument of a rule can only be an array of primitives!
  defineRule('datesAlreadyBooked', validatePeriodAgainstBookedDates)
  defineRule('gdprPassword', (value) => {
    for (const rule of gdprRulesDefault) {
      rule.state = rule.regex.test(value) ? 'valid' : 'failed'
    }

    if (
      gdprRulesDefault
        .map((rule) => rule.state)
        .some((state) => state !== 'valid')
    ) {
      return 'gdprPasswordInvalid'
    }

    return true
  })
  defineRule('bathroomForBedrooms', (value, _, ctx) => {
    if (ctx.form.bedrooms) {
      const bathrooms = Number(value)
      const bedrooms = Number(ctx.form.bedrooms)

      if (bathrooms >= Math.ceil(bedrooms / 2)) {
        return true
      }

      return false
    }

    console.error('bathroomForBedrooms rule must be used with bedrooms field')
  })
  defineRule(
    'validIdDocument',
    (value) => value === 'passport' || value === 'identityCard',
  )
  const validExpiryDate = (value, [checkOutDate, locale]) => {
    try {
      return dayjs
        .utc(value, locale === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY')
        .isAfter(checkOutDate)
    } catch {
      return false
    }
  }
  defineRule('passportValidExpiryDate', validExpiryDate)
  defineRule('identityCardValidExpiryDate', validExpiryDate)
  defineRule('minSurface', (value, [min]) => Number(value) >= Number(min))

  configure({
    generateMessage: localize({
      fr: {
        messages: {
          ...fr.messages,
          allInteger: 'Le champs doit être un nombre entier',
          calendarRequired:
            'Les champs date de début et de fin sont obligatoires',
          datesAlreadyBooked:
            "Vous ne pouvez pas passer à l'étape suivante, car les dates sélectionnées ne sont pas disponibles. Veuillez choisir d'autres dates.",
          dateFormat: 'Veuillez respecter le format JJ/MM/AAAA',
          googleSignIn: 'Vous devez vous connecter avec Google',
          greaterThanToday: 'La date doit être supérieur à la date du jour',
          hourMinuteFormat: 'Veuillez respecter le format HH:MM',
          identityCardValidExpiryDate:
            "La carte d'identité doit être valide jusqu'à la date du départ de la propriété.",
          minimumCommissionValue:
            'La valeur min de la com LC acceptée est de 0:{min} 1:{currency}',
          minSurface:
            'La superficie minimum de nos propriétés est de 0:{min} 1:{unit}',
          passportValidExpiryDate:
            "Le passeport doit être valide jusqu'à la date du départ de la propriété.",
          phone_invalid: "Ce numéro n'est pas valide",
          slug: 'Doit être un slug valide',
          UTF8Characters: 'Il y a un caractère invalide',
          validDate: "Cette date n'est pas valide",
          validBirthdate: "Cette date de naissance n'est pas valide",
          validDateOfBirth:
            "L'utilisateur doit avoir plus de 18 ans pour pouvoir signer le contrat.",
          bathroomForBedrooms: 'Un minimum de 1 salle de bain pour 2 chambres',
          validIdDocument: 'Vous devez sélectionner un document.',
        },
        names: {
          accountOwner: 'Titulaire du compte bancaire',
          address: 'Adresse',
          availabilitiesLink: 'Lien vers les disponibilités (optionnel)',
          bathrooms: 'Nombre de salles de bain',
          bedrooms: 'Nombre de chambres',
          bic: 'BIC',
          birthdate: 'Date de naissance',
          birthDate: 'Date de naissance',
          budget: 'Budget',
          budgetForStay: 'Budget',
          budgetPlaceholder: 'À partir de 1,5 million',
          capacity: 'Capacité maximale de la maison',
          cardName: 'Nom sur la carte',
          city: 'Ville',
          civility: 'Civilité',
          civilityFemale: 'Madame',
          forSale: 'Votre propriété est-elle également disponible à la vente ?',
          pricesLink: 'Lien vers la grille tarifaire (optionnel)',
          staff: 'Y a-t-il du personnel à disposition ?',
          civilityMale: 'Monsieur',
          civilityUnspecified: 'Non spécifié',
          client_account: 'Compte client',
          codeReception:
            'Afin de sécuriser la signature de votre contrat, une double authentification est nécessaire. Souhaitez-vous recevoir votre code de confirmation par ?',
          comment: 'Dites-nous en plus',
          comments: 'Commentaire',
          company: 'Société',
          companyActivity: 'Nature de votre activité',
          companyName: "Nom de l'entreprise",
          companyNumber: "Identifiant de l'entreprise",
          country: 'Pays',
          currency: 'Devise',
          measureUnit: 'Unité de mesure',
          countryCity: 'Pays, Ville (facultatif)',
          dealHubspot: 'Deal Hubspot',
          desiredDestination: 'Destination recherchée',
          email: 'e-mail',
          end_date: 'Date de départ',
          enterEmail: 'Renseignez votre e-mail',
          eventType: "Type d'événement",
          example: {
            bic: 'Ex : CCBPFRPPVER',
            iban: 'Ex : FR76 1180 8009 1012 3456 7890 1470 0910',
            rib: 'Formats autorisés : pdf, jpg, png',
            siret: 'Ex : 012 345 678 91233',
          },
          first_name: 'Prénom',
          firstname: 'Prénom',
          firstName: 'Prénom',
          guests: 'Nombre de voyageurs',
          houseAddress: 'Adresse du bien',
          housePrice: 'Prix de vente souhaité (hors frais d’agence)',
          iban: 'IBAN',
          identifier: 'E-mail',
          identity: 'Identité',
          idDocExpiryDate: "Date d'expiration",
          idDocNumber: 'Numéro du document',
          individual: 'Particulier',
          kind: 'Raison de votre demande',
          last_name: 'Nom',
          lastname: 'Nom',
          lastName: 'Nom',
          message: 'Message',
          messageContact: 'Demandez-nous l’impossible, nous sommes prêts !',
          name: 'Le nom',
          nationality: 'Nationalité',
          newPassword: 'Nouveau mot de passe',
          notes: 'Ecrivez votre requête ici',
          numberOfPeople: 'Nbre de personnes',
          optional: 'Facultatif',
          ownerType: 'Type de propriétaire',
          partnersComment:
            'Je recherche une maison à Ibiza pour mon client, du 4 juillet au 15 août, j’aimerais en savoir plus sur vos conditions de collaboration, etc',
          passwordConfirmation: 'Confirmez le mot de passe',
          password: 'Mot de passe',
          passwordDoNotMatch: 'Les mots de passe ne correspondent pas',
          passwordShowHide: 'Voir ou cacher le mot de passe',
          salePrice: 'Prix de vente souhaité',
          placeholder: {
            company: 'Choisir ou ajouter une société',
            date: 'JJ/MM/AAAA',
            noOptions: 'La liste est vide',
            noResult: 'Aucun résultat',
            select: 'Choisir',
            selectReason: 'Choisir une raison',
            selectYear: 'Sélectionnez une année',
            url: 'https://...',
          },
          phone: 'Téléphone mobile',
          photolink: 'Lien vers photos',
          postalCode: 'Code postal',
          registrationNumber:
            "Numéro d'enregistrement (pour la France, numéro de SIRET)",
          representativeCivility: 'Civilité du représentant',
          representativeFirstName: 'Prénom du représentant',
          representativeLastName: 'Nom du représentant',
          rib: 'RIB',
          shortCivilityFemale: 'Mme',
          shortCivilityMale: 'M.',
          siret: 'Numéro de SIRET',
          subject: 'Type de requête',
          start_date: "Date d'arrivée",
          surface: 'Superficie',
          tellUsProject: 'Décrivez-nous votre projet',
          tellUsProjectPlaceholder: 'Veuillez saisir moins de 3 000 caractères',
          termsAndConditions: {
            text: "J'accepte les {link} de la création de compte",
            link: 'termes et conditions',
          },
          terms_of_service_signed: 'Termes et conditions',
          termsOfServiceSigned: 'Termes et conditions',
          textareaErrorMessage: 'Votre message ne doit pas être vide.',
          title: 'Titre',
          timeOfTheDay: {
            morning: 'Le matin',
            noon: 'Le midi',
            afternoon: "L'après midi",
            evening: 'Le soir',
            asap: 'Je suis disponible maintenant',
          },
          url: 'Le lien',
          website: 'Site internet (facultatif)',
          whatsapp: 'WhatsApp',
          yourEmail: 'Votre e-mail',
          yourPassword: 'Votre mot de passe',
        },
      },
      en: {
        messages: {
          ...en.messages,
          allInteger: 'The field should be an integer',
          calendarRequired: 'The start and end date fields are both required',
          datesAlreadyBooked:
            'You cannot proceed to the next step because the selected dates are not available. Please choose different dates.',
          dateFormat: 'Please respect the format DD/MM/YYYY',
          googleSignIn: 'You must use Google to login',
          greaterThanToday: "Date must be greater than today's date",
          hourMinuteFormat: 'Please respect the format HH:MM',
          identityCardValidExpiryDate:
            'ID card must be valid until checkout date.',
          minimumCommissionValue:
            'The min value of LC com accepted is 0:{min} 1:{currency}',
          minSurface:
            'The minimum surface area of our properties is 0:{min} 1:{unit}',
          passportValidExpiryDate:
            'Passport must be valid until checkout date.',
          phone_invalid: 'This phone number is not valid',
          slug: 'Must be a valid slug',
          UTF8Characters: 'There is an invalid character',
          validDate: 'This date is not valid',
          validBirthdate: 'This birth date is not valid',
          validDateOfBirth:
            'User must be over 18 to be able to sign the contract.',
          bathroomForBedrooms: 'At least 1 bathroom for every 2 bedrooms',
          validIdDocument: 'You must select a document',
        },
        names: {
          address: 'address',
          capacity: 'Maximum house capacity',
          comment: 'comment',
          bathrooms: 'Number of bathrooms',
          bedrooms: 'Number of bedrooms',
          termsOfServiceSigned: 'terms of service signed',
          companyActivity: "company's activity",
          companyName: 'company name',
          country: 'country',
          dealHubspot: 'Hubspot deal',
          destinationName: 'desired destination',
          email: 'e-mail',
          eventKind: "event's type",
          firstname: 'first name',
          firstName: 'first name',
          kind: 'Ask us the impossible',
          birthdate: 'birthdate',
          lastname: 'last name',
          lastName: 'lastn ame',
          passwordConfirmation: 'password confirmation',
          postalCode: 'postal code',
          people: 'number of peoples',
          phone: 'mobile phone',
          photolink: 'photolink',
          surface: 'surface',
          tellUsProject: 'tell us more',
          accountOwner: 'Bank account owner',
          bic: 'BIC',
          birthDate: 'Birth date',
          budget: 'Budget',
          budgetForStay: 'Budget for stay',
          budgetPlaceholder: 'From 1,5 million',
          cardName: 'Name on the card',
          city: 'City',
          civility: 'Civility',
          civilityFemale: 'Ms',
          civilityMale: 'Mr',
          civilityUnspecified: 'Unspecified',
          client_account: 'Client account',
          codeReception:
            'To ensure the security of your contract signature, a double authentication is required. Would you like to receive your confirmation code by:',
          comments: 'Comment',
          company: 'Company',
          companyNumber: 'Company identifier',
          countryCity: 'Country, City (optional)',
          currency: 'Currency',
          desiredDestination: 'Desired destination',
          end_date: 'Check-out',
          measureUnit: 'Measure unit',
          enterEmail: 'Enter your e-mail',
          eventType: "Event's type",
          example: {
            bic: 'Ex: CCBPFRPPVER',
            iban: 'Ex: FR76 1180 8009 1012 3456 7890 1470 0910',
            rib: 'Accepted formats: pdf, jpg, png',
            siret: 'Ex: 012 345 678 91233',
          },
          first_name: 'First name',
          forSale: 'Is your property also available for sale?',
          guests: 'Number of guests',
          houseAddress: "Property's address",
          housePrice: 'Desired selling price (excluding agency fees)',
          iban: 'IBAN',
          identifier: 'E-mail',
          identity: 'Identity',
          idDocExpiryDate: 'expiry date',
          idDocNumber: 'document number',
          individual: 'Individual',
          last_name: 'Last name',
          message: 'Message',
          messageContact: "Ask us the impossible, we're ready!",
          name: 'The name',
          nationality: 'Nationality',
          newPassword: 'New password',
          notes: 'Write your requested modification here',
          numberOfPeople: 'Number of peoples',
          optional: 'Optional',
          ownerType: 'Type of owner',
          partnersComment:
            'I am looking for a house in Ibiza for my client, from 4th July to 15th August, I want to know more about your partnership agreement ...',
          password: 'Password',
          passwordDoNotMatch: 'Passwords do not match',
          passwordShowHide: 'Show or hide password',
          placeholder: {
            company: 'Choose or add a company',
            date: 'DD/MM/YYYY',
            noOptions: 'List is empty',
            noResult: 'No result',
            select: 'Choose',
            selectReason: 'Please select a reason',
            selectYear: 'Choose a year',
            url: 'https://...',
          },
          registrationNumber: 'Registration number',
          representativeCivility: 'Civility of representative',
          representativeFirstName: 'First name of representative',
          representativeLastName: 'Last name of representative',
          rib: 'Bank details',
          shortCivilityFemale: 'Ms',
          shortCivilityMale: 'Mr',
          siret: 'SIRET number',
          start_date: 'Check-in',
          subject: 'Type of request',
          tellUsProjectPlaceholder: 'Please enter less than 3 000 characters',
          termsAndConditions: {
            text: 'I accept the {link} of the account creation.',
            link: 'terms and conditions',
          },
          terms_of_service_signed: 'Terms and conditions',
          textareaErrorMessage: 'Your message should not be empty.',
          title: 'Title',
          timeOfTheDay: {
            morning: 'In the morning ',
            noon: 'Lunch time',
            afternoon: 'In the afternoon',
            evening: 'In the evening',
            asap: 'I am available now',
          },
          url: 'The link',
          website: 'Website (optional)',
          whatsapp: 'WhatsApp',
          yourEmail: 'Your e-mail',
          yourPassword: 'Your password',
        },
      },
    }),
  })

  // Set locale
  setLocale($i18n.locale.value)
})
