<script setup lang="ts">
import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js'

const { user } = useUser()
const { phoneNumber } = useContactPhoneNumber()

const { color = 'primary', phone = null } = defineProps<{
  color?: 'primary' | 'primary-light' | 'black'
  phone?: string | null
}>()
defineEmits<{
  click: [MouseEvent]
}>()

const phoneNumberOther = computed(() =>
  typeof phone === 'string' && phone
    ? parsePhoneNumber(
        phone,
        (user.value.country as CountryCode) ?? undefined,
      ).formatInternational()
    : null,
)
const phoneNumberLC = computed(() =>
  typeof phone !== 'string' ? phoneNumber.value : null,
)
</script>

<template>
  <BaseButton
    v-if="phoneNumberLC"
    :color="color"
    :href="`tel:${phoneNumberLC}`"
    variant="link"
    @click="$emit('click', $event)"
  >
    {{ phoneNumberLC }}
  </BaseButton>
  <span v-else-if="phoneNumberOther">{{ phoneNumberOther }}</span>
</template>
