
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexReSSuvr35fMeta } from "/vercel/path0/apps/site/pages/index.vue?macro=true";
import { default as _91name_93XIpfsi1ErGMeta } from "/vercel/path0/apps/site/pages/[destination]/[name].vue?macro=true";
import { default as _91filter_93lcdQeq9DzyMeta } from "/vercel/path0/apps/site/pages/[destination]/[slug]/[filter].vue?macro=true";
import { default as addedValueh5AjGYBlY0Meta } from "/vercel/path0/apps/site/pages/addedValue.vue?macro=true";
import { default as bonder8bZMgYW42HMeta } from "/vercel/path0/apps/site/pages/bonder.vue?macro=true";
import { default as contactK3j6k1mzq2Meta } from "/vercel/path0/apps/site/pages/contact.vue?macro=true";
import { default as customHolidaysGc31fZD7bIMeta } from "/vercel/path0/apps/site/pages/customHolidays.vue?macro=true";
import { default as eventsAvgsFNA1L5Meta } from "/vercel/path0/apps/site/pages/events.vue?macro=true";
import { default as _91id_93uuOy3ZRSf0Meta } from "/vercel/path0/apps/site/pages/feedback/[id].vue?macro=true";
import { default as _91slug_93otwdpyGQWtMeta } from "/vercel/path0/apps/site/pages/house/[slug].vue?macro=true";
import { default as photoss2ORbOv1YVMeta } from "/vercel/path0/apps/site/pages/house/photos.vue?macro=true";
import { default as erroripmyDn72M0Meta } from "/vercel/path0/apps/site/pages/house/preorder/error.vue?macro=true";
import { default as indexchLByKVEMoMeta } from "/vercel/path0/apps/site/pages/house/preorder/index.vue?macro=true";
import { default as successBZ0gCRtaG8Meta } from "/vercel/path0/apps/site/pages/house/preorder/success.vue?macro=true";
import { default as _91slug_93FQEy5y5OFeMeta } from "/vercel/path0/apps/site/pages/house/transaction/[slug].vue?macro=true";
import { default as photosqQ57VJjz6NMeta } from "/vercel/path0/apps/site/pages/house/transaction/photos.vue?macro=true";
import { default as houseCollectionEyRTVfEQN1Meta } from "/vercel/path0/apps/site/pages/houseCollection.vue?macro=true";
import { default as clientConditionsqL1XWJ0ZKKMeta } from "/vercel/path0/apps/site/pages/legal/clientConditions.vue?macro=true";
import { default as ownerConditionsNdbIE2Yu8kMeta } from "/vercel/path0/apps/site/pages/legal/ownerConditions.vue?macro=true";
import { default as privacyPolicyqvpVWv0wLcMeta } from "/vercel/path0/apps/site/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsOfUseyQJSVcii0xMeta } from "/vercel/path0/apps/site/pages/legal/termsOfUse.vue?macro=true";
import { default as loginIlqqHrfSkGMeta } from "/vercel/path0/apps/site/pages/login.vue?macro=true";
import { default as bookingsuDCFSFTiNMMeta } from "/vercel/path0/apps/site/pages/myaccount/bookings.vue?macro=true";
import { default as destinationAlertX4WaSgoQ7UMeta } from "/vercel/path0/apps/site/pages/myaccount/destinationAlert.vue?macro=true";
import { default as contractsSb3H8Iy0oSMeta } from "/vercel/path0/apps/site/pages/myaccount/documents/[house]/contracts.vue?macro=true";
import { default as _91id_93BkeaMOF6N0Meta } from "/vercel/path0/apps/site/pages/myaccount/documents/checks/[id].vue?macro=true";
import { default as indexrgum6srJuZMeta } from "/vercel/path0/apps/site/pages/myaccount/documents/index.vue?macro=true";
import { default as menuGGlcRsul6kMeta } from "/vercel/path0/apps/site/pages/myaccount/menu.vue?macro=true";
import { default as profileQ9WPvzZofjMeta } from "/vercel/path0/apps/site/pages/myaccount/profile.vue?macro=true";
import { default as calendarzxGDMaMKTlMeta } from "/vercel/path0/apps/site/pages/myaccount/property/[name]/calendar.vue?macro=true";
import { default as infoR5qhgHHsN4Meta } from "/vercel/path0/apps/site/pages/myaccount/property/[name]/info.vue?macro=true";
import { default as prices01xC2O2yuwMeta } from "/vercel/path0/apps/site/pages/myaccount/property/[name]/prices.vue?macro=true";
import { default as rentalssU0q4pDeI3Meta } from "/vercel/path0/apps/site/pages/myaccount/property/[name]/rentals.vue?macro=true";
import { default as salesgylOaSggGIMeta } from "/vercel/path0/apps/site/pages/myaccount/property/[name]/sales.vue?macro=true";
import { default as _91name_93bYK94uWkYpMeta } from "/vercel/path0/apps/site/pages/myaccount/property/[name].vue?macro=true";
import { default as ownersFX6fT0ePplMeta } from "/vercel/path0/apps/site/pages/owners.vue?macro=true";
import { default as errorniRnaWsTFDMeta } from "/vercel/path0/apps/site/pages/payment/[payin]/error.vue?macro=true";
import { default as indexjnWAMjV1pvMeta } from "/vercel/path0/apps/site/pages/payment/[payin]/index.vue?macro=true";
import { default as successqHL9hTbpDoMeta } from "/vercel/path0/apps/site/pages/payment/[payin]/success.vue?macro=true";
import { default as realEstatebHxS8PoYCkMeta } from "/vercel/path0/apps/site/pages/realEstate.vue?macro=true";
import { default as realEstateListingsefxmxvhPoDMeta } from "/vercel/path0/apps/site/pages/realEstateListings.vue?macro=true";
import { default as bookingConfirmationeQtUQjoedlMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/bookingConfirmation.vue?macro=true";
import { default as clientInformationn5Tu9RO9qiMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/clientInformation.vue?macro=true";
import { default as identityDocument4w2W3ndL1ZMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/identityDocument.vue?macro=true";
import { default as reservationRequestZdPCTX620KMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/reservationRequest.vue?macro=true";
import { default as resumeClientXPhaDwukPGMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeClient.vue?macro=true";
import { default as resumeOwnerzWBvEwgk5LMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeOwner.vue?macro=true";
import { default as successClientROUMQ1712bMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/successClient.vue?macro=true";
import { default as successOwner0dUw5NrQMXMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id]/successOwner.vue?macro=true";
import { default as _91id_938ATNhQE8ViMeta } from "/vercel/path0/apps/site/pages/reservationAgreement/[id].vue?macro=true";
import { default as villabookHkAEfMkJDZMeta } from "/vercel/path0/apps/site/pages/screenshot/villabook.vue?macro=true";
import { default as whitebrandcKQnSIpYeyMeta } from "/vercel/path0/apps/site/pages/screenshot/whitebrand.vue?macro=true";
import { default as searchVYquiA3OltMeta } from "/vercel/path0/apps/site/pages/search.vue?macro=true";
import { default as sitemapGaOMFiQ4t1Meta } from "/vercel/path0/apps/site/pages/sitemap.vue?macro=true";
import { default as _91token_93xGqpIBonVfMeta } from "/vercel/path0/apps/site/pages/stayProposal/[token].vue?macro=true";
import { default as editGIk0L5Sz8MMeta } from "/vercel/path0/apps/site/pages/stayProposal/edit.vue?macro=true";
import { default as tailor1LiStsm1osMeta } from "/vercel/path0/apps/site/pages/tailor.vue?macro=true";
import { default as tgvlJCXjOI4BLMeta } from "/vercel/path0/apps/site/pages/tgv.vue?macro=true";
import { default as travelPartners5MVVELfV91Meta } from "/vercel/path0/apps/site/pages/travelPartners.vue?macro=true";
import { default as typeformhC9eSSqRXdMeta } from "/vercel/path0/apps/site/pages/typeform.vue?macro=true";
import { default as vallatYCQsJX62LgMeta } from "/vercel/path0/apps/site/pages/vallat.vue?macro=true";
import { default as indexfFHdIOuekzMeta } from "/vercel/path0/apps/site/pages/wishlist/[token]/index.vue?macro=true";
import { default as sharedPtoRYgRJEyMeta } from "/vercel/path0/apps/site/pages/wishlist/[token]/shared.vue?macro=true";
import { default as indexiJCTWlpFn4Meta } from "/vercel/path0/apps/site/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexReSSuvr35fMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/index.vue")
  },
  {
    name: "destination-name___fr",
    path: "/fr/:destination()/:name()",
    meta: _91name_93XIpfsi1ErGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/[destination]/[name].vue")
  },
  {
    name: "destination-name___en",
    path: "/en/:destination()/:name()",
    meta: _91name_93XIpfsi1ErGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/[destination]/[name].vue")
  },
  {
    name: "destination-slug-filter___fr",
    path: "/fr/:destination()/:slug()/:filter()",
    meta: _91filter_93lcdQeq9DzyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/[destination]/[slug]/[filter].vue")
  },
  {
    name: "destination-slug-filter___en",
    path: "/en/:destination()/:slug()/:filter()",
    meta: _91filter_93lcdQeq9DzyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/[destination]/[slug]/[filter].vue")
  },
  {
    name: "addedValue___fr",
    path: "/fr/notre-valeur-ajoutee",
    meta: addedValueh5AjGYBlY0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/addedValue.vue")
  },
  {
    name: "addedValue___en",
    path: "/en/our-added-value",
    meta: addedValueh5AjGYBlY0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/addedValue.vue")
  },
  {
    name: "bonder___fr",
    path: "/fr/bonderco",
    meta: bonder8bZMgYW42HMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/bonder.vue")
  },
  {
    name: "bonder___en",
    path: "/en/bonderco",
    meta: bonder8bZMgYW42HMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/bonder.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    meta: contactK3j6k1mzq2Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactK3j6k1mzq2Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/contact.vue")
  },
  {
    name: "customHolidays___fr",
    path: "/fr/vacances-sur-mesure",
    meta: customHolidaysGc31fZD7bIMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/customHolidays.vue")
  },
  {
    name: "customHolidays___en",
    path: "/en/custom-holidays",
    meta: customHolidaysGc31fZD7bIMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/customHolidays.vue")
  },
  {
    name: "events___fr",
    path: "/fr/service-evenementiel",
    meta: eventsAvgsFNA1L5Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/events.vue")
  },
  {
    name: "events___en",
    path: "/en/event-service",
    meta: eventsAvgsFNA1L5Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/events.vue")
  },
  {
    name: "feedback-id___fr",
    path: "/fr/formulaire-de-satisfaction/:id()",
    component: () => import("/vercel/path0/apps/site/pages/feedback/[id].vue")
  },
  {
    name: "feedback-id___en",
    path: "/en/feedback/:id()",
    component: () => import("/vercel/path0/apps/site/pages/feedback/[id].vue")
  },
  {
    name: "house-slug___fr",
    path: "/fr/location-luxe/:slug()",
    meta: _91slug_93otwdpyGQWtMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/[slug].vue")
  },
  {
    name: "house-slug___en",
    path: "/en/luxury-rental/:slug()",
    meta: _91slug_93otwdpyGQWtMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/[slug].vue")
  },
  {
    name: "house-photos___fr",
    path: "/fr/location-luxe/:slug()/photos",
    meta: photoss2ORbOv1YVMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/photos.vue")
  },
  {
    name: "house-photos___en",
    path: "/en/luxury-rental/:slug()/photos",
    meta: photoss2ORbOv1YVMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/photos.vue")
  },
  {
    name: "house-preorder-error___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option/erreur",
    meta: erroripmyDn72M0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/error.vue")
  },
  {
    name: "house-preorder-error___en",
    path: "/en/luxury-rental/:slug()/pre-order/error",
    meta: erroripmyDn72M0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/error.vue")
  },
  {
    name: "house-preorder___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option",
    meta: indexchLByKVEMoMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/index.vue")
  },
  {
    name: "house-preorder___en",
    path: "/en/luxury-rental/:slug()/pre-order",
    meta: indexchLByKVEMoMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/index.vue")
  },
  {
    name: "house-preorder-success___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option/succes",
    meta: successBZ0gCRtaG8Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/success.vue")
  },
  {
    name: "house-preorder-success___en",
    path: "/en/luxury-rental/:slug()/pre-order/success",
    meta: successBZ0gCRtaG8Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/preorder/success.vue")
  },
  {
    name: "house-transaction-slug___fr",
    path: "/fr/immobilier-luxe/:slug()",
    meta: _91slug_93FQEy5y5OFeMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/transaction/[slug].vue")
  },
  {
    name: "house-transaction-slug___en",
    path: "/en/luxury-real-estate/:slug()",
    meta: _91slug_93FQEy5y5OFeMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/transaction/[slug].vue")
  },
  {
    name: "house-transaction-photos___fr",
    path: "/fr/immobilier-luxe/:slug()/photos",
    meta: photosqQ57VJjz6NMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/transaction/photos.vue")
  },
  {
    name: "house-transaction-photos___en",
    path: "/en/luxury-real-estate/:slug()/photos",
    meta: photosqQ57VJjz6NMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/house/transaction/photos.vue")
  },
  {
    name: "houseCollection___fr",
    path: "/fr/collection-de-maisons",
    component: () => import("/vercel/path0/apps/site/pages/houseCollection.vue")
  },
  {
    name: "houseCollection___en",
    path: "/en/collection-of-houses",
    component: () => import("/vercel/path0/apps/site/pages/houseCollection.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexReSSuvr35fMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexReSSuvr35fMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/index.vue")
  },
  {
    name: "legal-clientConditions___fr",
    path: "/fr/conditions-client",
    meta: clientConditionsqL1XWJ0ZKKMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/clientConditions.vue")
  },
  {
    name: "legal-clientConditions___en",
    path: "/en/client-conditions",
    meta: clientConditionsqL1XWJ0ZKKMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/clientConditions.vue")
  },
  {
    name: "legal-ownerConditions___fr",
    path: "/fr/conditions-proprietaire",
    meta: ownerConditionsNdbIE2Yu8kMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/ownerConditions.vue")
  },
  {
    name: "legal-ownerConditions___en",
    path: "/en/owner-conditions",
    meta: ownerConditionsNdbIE2Yu8kMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/ownerConditions.vue")
  },
  {
    name: "legal-privacyPolicy___fr",
    path: "/fr/politique-confidentialite",
    meta: privacyPolicyqvpVWv0wLcMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-privacyPolicy___en",
    path: "/en/privacy-policy",
    meta: privacyPolicyqvpVWv0wLcMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-termsOfUse___fr",
    path: "/fr/conditions-generales-utilisation",
    meta: termsOfUseyQJSVcii0xMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/termsOfUse.vue")
  },
  {
    name: "legal-termsOfUse___en",
    path: "/en/terms-of-use",
    meta: termsOfUseyQJSVcii0xMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/legal/termsOfUse.vue")
  },
  {
    name: "login___fr",
    path: "/fr/connexion",
    meta: loginIlqqHrfSkGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginIlqqHrfSkGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/login.vue")
  },
  {
    name: "myaccount-bookings___fr",
    path: "/fr/mon-compte/reservations",
    meta: bookingsuDCFSFTiNMMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount-bookings___en",
    path: "/en/my-account/bookings",
    meta: bookingsuDCFSFTiNMMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount-destinationAlert___fr",
    path: "/fr/mon-compte/mes-alertes-destination",
    meta: destinationAlertX4WaSgoQ7UMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/destinationAlert.vue")
  },
  {
    name: "myaccount-destinationAlert___en",
    path: "/en/my-account/my-destination-alerts",
    meta: destinationAlertX4WaSgoQ7UMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/destinationAlert.vue")
  },
  {
    name: "myaccount-documents-house-contracts___fr",
    path: "/fr/mon-compte/documents/:houseId()/contrats",
    meta: contractsSb3H8Iy0oSMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/[house]/contracts.vue")
  },
  {
    name: "myaccount-documents-house-contracts___en",
    path: "/en/my-account/documents/:houseId()/contracts",
    meta: contractsSb3H8Iy0oSMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/[house]/contracts.vue")
  },
  {
    name: "myaccount-documents-checks-id___fr",
    path: "/fr/mon-compte/documents/checks/:id()",
    meta: _91id_93BkeaMOF6N0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/checks/[id].vue")
  },
  {
    name: "myaccount-documents-checks-id___en",
    path: "/en/my-account/documents/checks/:id()",
    meta: _91id_93BkeaMOF6N0Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/checks/[id].vue")
  },
  {
    name: "myaccount-documents___fr",
    path: "/fr/mon-compte/documents",
    meta: indexrgum6srJuZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/index.vue")
  },
  {
    name: "myaccount-documents___en",
    path: "/en/my-account/documents",
    meta: indexrgum6srJuZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/documents/index.vue")
  },
  {
    name: "myaccount-menu___fr",
    path: "/fr/mon-compte/menu",
    meta: menuGGlcRsul6kMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/menu.vue")
  },
  {
    name: "myaccount-menu___en",
    path: "/en/my-account/menu",
    meta: menuGGlcRsul6kMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/menu.vue")
  },
  {
    name: "myaccount-profile___fr",
    path: "/fr/mon-compte/profil",
    meta: profileQ9WPvzZofjMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/profile.vue")
  },
  {
    name: "myaccount-profile___en",
    path: "/en/my-account/profile",
    meta: profileQ9WPvzZofjMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/profile.vue")
  },
  {
    name: "myaccount-property-name___fr",
    path: "/fr/mon-compte/proprietes/:name()",
    meta: _91name_93bYK94uWkYpMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name].vue"),
    children: [
  {
    name: "myaccount-property-name-calendar___fr",
    path: "calendrier",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/calendar.vue")
  },
  {
    name: "myaccount-property-name-info___fr",
    path: "informations-contrat",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/info.vue")
  },
  {
    name: "myaccount-property-name-prices___fr",
    path: "prix",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/prices.vue")
  },
  {
    name: "myaccount-property-name-rentals___fr",
    path: "locations",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/rentals.vue")
  },
  {
    name: "myaccount-property-name-sales___fr",
    path: "ventes",
    meta: salesgylOaSggGIMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/sales.vue")
  }
]
  },
  {
    name: "myaccount-property-name___en",
    path: "/en/my-account/properties/:name()",
    meta: _91name_93bYK94uWkYpMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name].vue"),
    children: [
  {
    name: "myaccount-property-name-calendar___en",
    path: "calendar",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/calendar.vue")
  },
  {
    name: "myaccount-property-name-info___en",
    path: "contract-information",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/info.vue")
  },
  {
    name: "myaccount-property-name-prices___en",
    path: "prices",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/prices.vue")
  },
  {
    name: "myaccount-property-name-rentals___en",
    path: "rentals",
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/rentals.vue")
  },
  {
    name: "myaccount-property-name-sales___en",
    path: "sales",
    meta: salesgylOaSggGIMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/myaccount/property/[name]/sales.vue")
  }
]
  },
  {
    name: "owners___fr",
    path: "/fr/proprietaires",
    meta: ownersFX6fT0ePplMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/owners.vue")
  },
  {
    name: "owners___en",
    path: "/en/owners",
    meta: ownersFX6fT0ePplMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/owners.vue")
  },
  {
    name: "payment-payin-error___fr",
    path: "/fr/paiements/:payin()/erreur",
    meta: errorniRnaWsTFDMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/error.vue")
  },
  {
    name: "payment-payin-error___en",
    path: "/en/payments/:payin()/error",
    meta: errorniRnaWsTFDMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/error.vue")
  },
  {
    name: "payment-payin___fr",
    path: "/fr/paiements/:payin()",
    meta: indexjnWAMjV1pvMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/index.vue")
  },
  {
    name: "payment-payin___en",
    path: "/en/payments/:payin()",
    meta: indexjnWAMjV1pvMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/index.vue")
  },
  {
    name: "payment-payin-success___fr",
    path: "/fr/paiements/:payin()/succes",
    meta: successqHL9hTbpDoMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/success.vue")
  },
  {
    name: "payment-payin-success___en",
    path: "/en/payments/:payin()/success",
    meta: successqHL9hTbpDoMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/payment/[payin]/success.vue")
  },
  {
    name: "realEstate___fr",
    path: "/fr/immobilier-luxe-service",
    meta: realEstatebHxS8PoYCkMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/realEstate.vue")
  },
  {
    name: "realEstate___en",
    path: "/en/luxury-real-estate-service",
    meta: realEstatebHxS8PoYCkMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/realEstate.vue")
  },
  {
    name: "realEstateListings___fr",
    path: "/fr/immobilier-luxe-annonces",
    meta: realEstateListingsefxmxvhPoDMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/realEstateListings.vue")
  },
  {
    name: "realEstateListings___en",
    path: "/en/luxury-real-estate-listing",
    meta: realEstateListingsefxmxvhPoDMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/realEstateListings.vue")
  },
  {
    name: "reservationAgreement-id___fr",
    path: "/fr/reservationAgreement/:id()",
    meta: _91id_938ATNhQE8ViMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id].vue"),
    children: [
  {
    name: "reservationAgreement-id-bookingConfirmation___fr",
    path: "/fr/contrat-de-location/:id()/confirmer",
    meta: bookingConfirmationeQtUQjoedlMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/bookingConfirmation.vue")
  },
  {
    name: "reservationAgreement-id-clientInformation___fr",
    path: "/fr/contrat-de-location/:id()/information-client",
    meta: clientInformationn5Tu9RO9qiMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/clientInformation.vue")
  },
  {
    name: "reservationAgreement-id-identityDocument___fr",
    path: "/fr/contrat-de-location/:id()/document-identite",
    meta: identityDocument4w2W3ndL1ZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/identityDocument.vue")
  },
  {
    name: "reservationAgreement-id-reservationRequest___fr",
    path: "/fr/demande-de-reservation/:id()/confirmer",
    meta: reservationRequestZdPCTX620KMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/reservationRequest.vue")
  },
  {
    name: "reservationAgreement-id-resumeClient___fr",
    path: "/fr/contrat-de-location/:id()/recapitulatif",
    meta: resumeClientXPhaDwukPGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeClient.vue")
  },
  {
    name: "reservationAgreement-id-resumeOwner___fr",
    path: "/fr/demande-de-reservation/:id()/recapitulatif",
    meta: resumeOwnerzWBvEwgk5LMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeOwner.vue")
  },
  {
    name: "reservationAgreement-id-successClient___fr",
    path: "/fr/contrat-de-location/:id()/paiement",
    meta: successClientROUMQ1712bMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/successClient.vue")
  },
  {
    name: "reservationAgreement-id-successOwner___fr",
    path: "/fr/demande-de-reservation/:id()/accepter",
    meta: successOwner0dUw5NrQMXMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/successOwner.vue")
  }
]
  },
  {
    name: "reservationAgreement-id___en",
    path: "/en/reservationAgreement/:id()",
    meta: _91id_938ATNhQE8ViMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id].vue"),
    children: [
  {
    name: "reservationAgreement-id-bookingConfirmation___en",
    path: "/en/rental-contract/:id()/confirmation",
    meta: bookingConfirmationeQtUQjoedlMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/bookingConfirmation.vue")
  },
  {
    name: "reservationAgreement-id-clientInformation___en",
    path: "/en/rental-contract/:id()/client-information",
    meta: clientInformationn5Tu9RO9qiMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/clientInformation.vue")
  },
  {
    name: "reservationAgreement-id-identityDocument___en",
    path: "/en/rental-contract/:id()/identity-documents",
    meta: identityDocument4w2W3ndL1ZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/identityDocument.vue")
  },
  {
    name: "reservationAgreement-id-reservationRequest___en",
    path: "/en/reservation-request/:id()/confirm",
    meta: reservationRequestZdPCTX620KMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/reservationRequest.vue")
  },
  {
    name: "reservationAgreement-id-resumeClient___en",
    path: "/en/rental-contract/:id()/resume",
    meta: resumeClientXPhaDwukPGMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeClient.vue")
  },
  {
    name: "reservationAgreement-id-resumeOwner___en",
    path: "/en/reservation-request/:id()/resume",
    meta: resumeOwnerzWBvEwgk5LMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/resumeOwner.vue")
  },
  {
    name: "reservationAgreement-id-successClient___en",
    path: "/en/rental-contract/:id()/payment",
    meta: successClientROUMQ1712bMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/successClient.vue")
  },
  {
    name: "reservationAgreement-id-successOwner___en",
    path: "/en/reservation-request/:id()/accept-client",
    meta: successOwner0dUw5NrQMXMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/reservationAgreement/[id]/successOwner.vue")
  }
]
  },
  {
    name: "screenshot-villabook___fr",
    path: "/fr/villabook/:id()",
    meta: villabookHkAEfMkJDZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/screenshot/villabook.vue")
  },
  {
    name: "screenshot-villabook___en",
    path: "/en/villabook/:id()",
    meta: villabookHkAEfMkJDZMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/screenshot/villabook.vue")
  },
  {
    name: "screenshot-whitebrand___fr",
    path: "/fr/preview/:id()",
    meta: whitebrandcKQnSIpYeyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/screenshot/whitebrand.vue")
  },
  {
    name: "screenshot-whitebrand___en",
    path: "/en/preview/:id()",
    meta: whitebrandcKQnSIpYeyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/screenshot/whitebrand.vue")
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    meta: searchVYquiA3OltMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchVYquiA3OltMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/search.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/plan-du-site",
    meta: sitemapGaOMFiQ4t1Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    meta: sitemapGaOMFiQ4t1Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/sitemap.vue")
  },
  {
    name: "stayProposal-token___fr",
    path: "/fr/proposition-de-sejour/:token()",
    meta: _91token_93xGqpIBonVfMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/stayProposal/[token].vue")
  },
  {
    name: "stayProposal-token___en",
    path: "/en/stay-proposal/:token()",
    meta: _91token_93xGqpIBonVfMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/stayProposal/[token].vue")
  },
  {
    name: "stayProposal-edit___fr",
    path: "/fr/modifier-proposition-de-sejour",
    meta: editGIk0L5Sz8MMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/stayProposal/edit.vue")
  },
  {
    name: "stayProposal-edit___en",
    path: "/en/edit-stay-proposal",
    meta: editGIk0L5Sz8MMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/stayProposal/edit.vue")
  },
  {
    name: "tailor___fr",
    path: "/fr/notre-conciergerie-de-luxe",
    meta: tailor1LiStsm1osMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/tailor.vue")
  },
  {
    name: "tailor___en",
    path: "/en/our-luxury-concierge-service",
    meta: tailor1LiStsm1osMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/tailor.vue")
  },
  {
    name: "tgv___fr",
    path: "/fr/the-greek-villas",
    meta: tgvlJCXjOI4BLMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/tgv.vue")
  },
  {
    name: "tgv___en",
    path: "/en/the-greek-villas",
    meta: tgvlJCXjOI4BLMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/tgv.vue")
  },
  {
    name: "travelPartners___fr",
    path: "/fr/agences-partenaires",
    meta: travelPartners5MVVELfV91Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/travelPartners.vue")
  },
  {
    name: "travelPartners___en",
    path: "/en/travel-partners",
    meta: travelPartners5MVVELfV91Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/travelPartners.vue")
  },
  {
    name: "typeform___fr",
    path: "/fr/demande-sur-mesure",
    meta: typeformhC9eSSqRXdMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/typeform.vue")
  },
  {
    name: "typeform___en",
    path: "/en/tailor-made-holiday",
    meta: typeformhC9eSSqRXdMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/typeform.vue")
  },
  {
    name: "vallat___fr",
    path: "/fr/vallat",
    meta: vallatYCQsJX62LgMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/vallat.vue")
  },
  {
    name: "vallat___en",
    path: "/en/vallat",
    meta: vallatYCQsJX62LgMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/vallat.vue")
  },
  {
    name: "wishlist-token___fr",
    path: "/fr/wishlist/:token()",
    meta: indexfFHdIOuekzMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/[token]/index.vue")
  },
  {
    name: "wishlist-token___en",
    path: "/en/wishlist/:token()",
    meta: indexfFHdIOuekzMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/[token]/index.vue")
  },
  {
    name: "wishlist-token-shared___fr",
    path: "/fr/wishlist/:token()/partage",
    meta: sharedPtoRYgRJEyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/[token]/shared.vue")
  },
  {
    name: "wishlist-token-shared___en",
    path: "/en/wishlist/:token()/shared",
    meta: sharedPtoRYgRJEyMeta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/[token]/shared.vue")
  },
  {
    name: "wishlist___fr",
    path: "/fr/wishlist",
    meta: indexiJCTWlpFn4Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/index.vue")
  },
  {
    name: "wishlist___en",
    path: "/en/wishlist",
    meta: indexiJCTWlpFn4Meta || {},
    component: () => import("/vercel/path0/apps/site/pages/wishlist/index.vue")
  }
]